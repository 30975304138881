import * as React from 'react'
import Layout from '../layout'
import FoodPage from '../pagesComponents/food'
import { navigate } from 'gatsby'

const Food = () => {
	// return navigate('/')

	return (
		<Layout title="Order Food & Drink's">
			<FoodPage />
		</Layout>
	)
}

export default Food
