import * as React from 'react'
import BasePage from '../components/basePage'
import { useAuth } from '../hooks/useAuth'
import { use100vh } from 'react-div-100vh'

const FoodPage = () => {
	const { profile } = useAuth()
	const height100vh = use100vh()

	return (
		<BasePage
			theme="dark"
			noHeader={true}
			noPadding={true}
			showMenuBtn={true}
		>
			<iframe
				src={`https://wearefairgame.theloyaltyco.app?cardId=${profile?.rfid}`}
				style={{
					marginTop: -10,
					width: '100%',
					height: height100vh || '100vh',
					background: '#1D1D1D'
				}}
				allow="camera https://wearefairgame.theloyaltyco.app/; microphone https://wearefairgame.theloyaltyco.app/"
			/>
		</BasePage>
	)
}

export default FoodPage
